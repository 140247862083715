import React from 'react'

import FormSuccessTemplate from '../../../components/templates/FormSuccessTemplate'

const Success = () => {
  return (
    <FormSuccessTemplate
      title="Succès - Envoi message annonce"
      buttonLabel='Retour aux annonces'
      redirection='/nos-annonces/'
      messageTitle='Votre demande a bien été envoyé au propriétaire !'
      messageContent="Vos coordonnées et votre message a été transmis au propriétaire de l&apos;annonce qui pourra vous contacter directement."
      description="Vos coordonnées et votre message a été transmis au propriétaire de l&apos;annonce qui pourra vous contacter directement." />
  )
}

export default Success
