import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../atoms/Seo'
import Navbar from '../../organisms/Navbar'
import Footer from '../../organisms/Footer'
import ConfirmationModal from '../../atoms/ConfirmationModal'

const FormSuccessTemplate = ({ title, location, redirection, description, messageTitle, messageContent, messageSubContent, buttonLabel }) => {
  return (
    <div>
      <SEO
        title={title}
        location={location}
        description={description}>
      </SEO>
      <Navbar />
      <ConfirmationModal
        show={true}
        redirection={redirection}
        buttonLabel={buttonLabel}
        messageTitle={messageTitle}
        messageContent={messageContent}
        messageSubContent={messageSubContent}
        updateShow={() => { }} />
      <Footer />
    </div>
  )
}

FormSuccessTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.object,
  redirection: PropTypes.string,
  buttonLabel: PropTypes.string,
  messageTitle: PropTypes.string,
  messageContent: PropTypes.string,
  messageSubContent: PropTypes.string,
  description: PropTypes.string.isRequired
}

export default FormSuccessTemplate
