import React, { useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from '../../../config/theme'
import CustomModal from '../Modal'
import { get } from 'lodash'

const ModalContainer = styled.div`
  padding: 40px;
  max-width: 500px;
`

const StyledModal = styled(CustomModal)`
  z-index: 100;
`

const Inline = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ModalText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
`

const ModalTitle = styled(ModalText)`
  font-weight: bold;
  font-size: 18px;
`

const ModalStrongText = styled(ModalText)`
  font-weight: bold;
`
const ModalButton = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 25px;
  margin: auto;
  padding: 8px 24px;
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`

const ConfirmationModal = ({ show, messageTitle, redirection, messageContent, messageSubContent, buttonLabel, updateShow }) => {
  const handleHomePage = useCallback(() => {
    navigate(redirection)
    updateShow(false)
  }, [redirection])

  return (
    <StyledModal isOpen={show} backgroundBlur={true} onRequestClose={() => updateShow(false)}>
      <ModalContainer>
        <Inline>
          <FontAwesomeIcon
            icon={['far', 'circle-check']}
            color={get(theme, 'green')} />
          <ModalTitle>{messageTitle}</ModalTitle>
        </Inline>
        <div>
          <ModalText>{messageContent}</ModalText>
          <ModalStrongText>{messageSubContent}</ModalStrongText>
        </div>
        <ModalButton onClick={handleHomePage}>{buttonLabel}</ModalButton>
      </ModalContainer>
    </StyledModal>
  )
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  updateShow: PropTypes.func.isRequired,
  redirection: PropTypes.string,
  buttonLabel: PropTypes.string,
  messageTitle: PropTypes.string,
  messageContent: PropTypes.string,
  messageSubContent: PropTypes.string
}

ConfirmationModal.defaultProps = {
  redirection: '/',
  buttonLabel: 'Retour à la home page',
  messageTitle: 'Votre demande est bien enregistrée !',
  messageContent: 'Vos informations viennent d\'être transmises à l\'équipe commerciale.',
  messageSubContent: 'Vous allez être contacté très rapidement !'
}
export default ConfirmationModal
